var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "err-con"
  }, [_c("div", {
    staticClass: "err404",
    style: {
      backgroundImage: "url(" + _vm.bgImg + ")"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };