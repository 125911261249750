export default {
  name: 'NotFound',
  data() {
    return {
      bgImg: require('@/assets/images/404_bg.png')
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};